<template>
  <b-card>
    <b-card-header>
      <h3>Générer un rapport</h3>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col md="12">
          <h1 class="section-label mx-0 mb-2">Paramètre du rapport</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-form-group label="Type de rapport" label-for="reportType">
            <v-select
              id="reportType"
              v-model="reportType"
              :clearable="false"
              :options="reportsType"
              label="text"
              :reduce="(option) => option.value"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div
        v-if="
          ['salesReport', 'salesReportByDepartureDate'].includes(reportType)
        "
      >
        <b-row>
          <b-col md="12">
            <h1 class="section-label mt-2 mx-0 mb-2">
              {{
                reportType == "salesReport"
                  ? "Date de réservation"
                  : "Date de départ"
              }}
            </h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <b-form-group label="Du" label-for="startDate">
              <b-form-datepicker
                id="startDate"
                locale="fr"
                v-model="reportStartDate"
                class="mb-1"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Au" label-for="endDate">
              <b-form-datepicker
                id="endDate"
                locale="fr"
                v-model="reportEndDate"
                class="mb-1"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="!isAdminAgency" cols="4">
            <b-form-group label="Agences" label-for="selectedAgency">
              <v-select
                id="selectedAgency"
                v-model="selectedAgency"
                :clearable="false"
                :options="availableAgencies"
                label="text"
                :reduce="(option) => option.value"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Statut des réservations"
              label-for="includeCancelledReservations"
            >
              <b-form-checkbox
                id="includeCancelledReservations"
                v-model="includeCancelledReservations"
                name="includeCancelledReservations"
                value="1"
                unchecked-value="0"
              >
                Inclure les réservations annulées
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <div
          v-if="
            1 == 2 &&
            ['salesReport', 'salesReportByDepartureDate'].includes(reportType)
          "
        >
          <b-row>
            <b-col md="12">
              <h1 class="section-label mt-2 mx-0 mb-2">
                Date des réservations
              </h1>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b-form-group
                label="Date de début"
                label-for="reservationStartDate"
              >
                <b-form-datepicker
                  id="reservationStartDate"
                  locale="fr"
                  v-model="reportReservationStartDate"
                  class="mb-1"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Date de fin" label-for="reservationEndDate">
                <b-form-datepicker
                  id="reservationEndDate"
                  locale="fr"
                  v-model="reportReservationEndDate"
                  class="mb-1"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>

      <div v-if="reportType === 'totalPax'">
        <b-row>
          <b-col md="12">
            <h1 class="section-label mt-2 mx-0 mb-2">Année financière</h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <b-form-group label="Année" label-for="financialYear">
              <v-select
                v-model="financialYear"
                :options="availableFinancialYears"
                :clearable="false"
                input-id="financial-year"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-card-body>
    <b-card-footer>
      <b-button
        variant="primary"
        class="float-right"
        :disabled="generatingReport"
        @click="generateSelectedReport"
      >
        <span>Générer</span>
        <b-spinner v-if="generatingReport" small class="ml-1"></b-spinner>
      </b-button>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BAlert,
  BLink,
  BCardHeader,
  BCardBody,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BButton,
  BCardFooter,
  BSpinner,
} from "bootstrap-vue";
import { ref, reactive } from "@vue/composition-api";
import vSelect from "vue-select";
import { useReportingApi } from "@/modules/organization/composables/use-reporting-api";
import { GroupReservationService } from "@/views/groups/services/GroupReservationService";
import { useToastNotification } from "@/shared/composables/use-toast-notifications";
import {
  AUTH_STORE_NAMESPACE,
  AUTHENTICATED_USER_GETTER,
} from "@/modules/authnz/models/store";
import { useApplicationContext } from "@/shared/composables/use-application-context";
import Roles from "@/modules/authnz/models/role";
export default {
  components: {
    BCardHeader,
    BCol,
    BRow,
    BCard,
    BAlert,
    BLink,
    BCardBody,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormDatepicker,
    BButton,
    BCardFooter,
    BSpinner,
  },
  setup() {
    const { getTotalPaxReport, getSalesReport, getSalesReportByDepartureDate } =
      useReportingApi();
    const { displayErrorMessage } = useToastNotification();
    const { store } = useApplicationContext();

    let generatingReport = ref(false);
    let reportType = ref();
    let reportStartDate = ref();
    let reportEndDate = ref();
    let reportReservationStartDate = ref();
    let reportReservationEndDate = ref();
    let selectedAgency = ref("all");
    let includeCancelledReservations = ref("1");
    let availableAgencies = ref([]);
    let financialYear = ref("");

    const groupReservationService = new GroupReservationService();
    const fetchAgencies = async () => {
      const agencies = await groupReservationService.getAllAgencies();
      availableAgencies.value.push({
        text: "Toutes les agences",
        value: "all",
      });
      availableAgencies.value.push(
        ...agencies.data
          .map((x) => {
            return { text: x.name, value: x.id };
          })
          .sort((a, b) => a.text.localeCompare(b.text))
      );
    };

    fetchAgencies();
    const currentAuthUserRole = ref({});
    currentAuthUserRole.value =
      store.getters[
        `${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`
      ].role.role;

    let reportsType = [
      { text: "Total de voyageurs par agences", value: "totalPax" },
      { text: "Rapport des ventes", value: "salesReport" },
      {
        text: "Rapport des ventes par date de départ",
        value: "salesReportByDepartureDate",
      },
    ];

    let availableFinancialYears = [];
    const now = new Date();
    for (let i = 2022; i <= now.getFullYear(); i++) {
      availableFinancialYears.push({
        label: i,
        value: i
      })
    }

    let isAdminAgency = Roles.roleIsAllowed(currentAuthUserRole.value, [
      Roles.AGENCY_ADMIN.role,
    ]);

    if (isAdminAgency) {
      reportsType = [
        { text: "Rapport des ventes", value: "salesReport" },
        {
          text: "Rapport des ventes par date de départ",
          value: "salesReportByDepartureDate",
        },
      ];
    }
    const generateSelectedReport = async () => {
      generatingReport.value = true;

      switch (reportType.value) {
        case "totalPax":
          await generateTotalPaxReport();
          break;
        case "salesReport":
          await generateSalesReport();
          break;
        case "salesReportByDepartureDate":
          await generateSalesReportByDepartureDate();
          break;
      }
      generatingReport.value = false;
    };

    const generateTotalPaxReport = async () => {
      let params = {
        type: reportType.value,
        financialYear: financialYear.value.value
      };

      try {
        const response = await getTotalPaxReport(params);
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data])
        );
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        let name = `TotalPaxReport-${financialYear.value.value}.xlsx`;

        fileLink.setAttribute("download", name);
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch {
        displayErrorMessage(
          "Une erreur est survenue en essayant de générer le rapport"
        );
      }
    };

    const generateSalesReport = async () => {
      let params = {
        startDate: reportStartDate.value,
        endDate: reportEndDate.value,
        includeCancelledReservations:
          includeCancelledReservations.value === "1",
        agencyId: selectedAgency.value != "all" ? selectedAgency.value : null,
      };

      try {
        const response = await getSalesReport(params);
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        let name = `Rapport-des-ventes.xlsx`;

        fileLink.setAttribute("download", name);
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch {
        displayErrorMessage(
          "Une erreur est survenue en essayant de générer le rapport"
        );
      }
    };

    const generateSalesReportByDepartureDate = async () => {
      let params = {
        startDate: reportStartDate.value,
        endDate: reportEndDate.value,
        includeCancelledReservations:
          includeCancelledReservations.value === "1",
        agencyId: selectedAgency.value != "all" ? selectedAgency.value : null,
      };

      try {
        const response = await getSalesReportByDepartureDate(params);
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        let name = `Rapport-des-ventes.xlsx`;

        fileLink.setAttribute("download", name);
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch {
        displayErrorMessage(
          "Une erreur est survenue en essayant de générer le rapport"
        );
      }
    };

    return {
      reportsType,
      isAdminAgency,
      financialYear,
      availableAgencies,
      availableFinancialYears,
      generatingReport,
      generateTotalPaxReport,
      reportType,
      reportStartDate,
      reportEndDate,
      reportReservationStartDate,
      reportReservationEndDate,
      selectedAgency,
      includeCancelledReservations,
      generateSelectedReport,
      generateSalesReportByDepartureDate,
    };
  },
  methods: {},
};
</script>
