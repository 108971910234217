import {useApplicationContext} from "@/shared/composables/use-application-context";
import {useApiRequestMappers} from "@/shared/composables/use-api-request-mappers";

export const useReportingApi = () => {
  const {logger, httpClient} = useApplicationContext();
  const {keysToSnakeCase, keysToCamel} = useApiRequestMappers();


  const getTotalPaxReport = async (params) => {

    try {
      logger.debug(`Generating total_pax report `);
      const response = await httpClient.post(`/reporting/total_pax`, {
        timeout: 120000,
        year: params.financialYear,
      }, {
        responseType: 'arraybuffer',
      });

      logger.debug(`report sending to download`);
      return response;
    } catch (error) {
      logger.error('Failed to generate report', error);
      throw  error;
    }
  };

  const getSalesReport = async (params) => {
    const requestData = keysToSnakeCase(params);

    try {
      const response = await httpClient.post(`/reporting/sales`, requestData, {
        timeout: 120000,
        responseType: 'arraybuffer',
      });
      return response;
    } catch (error) {
      logger.error('Failed to generate report', error);
      throw  error;
    }
  };

  const getSalesReportByDepartureDate = async (params) => {
    const requestData = keysToSnakeCase(params);

    try {
      const response = await httpClient.post(`/reporting/sales-by-departure-date`, requestData, {
        timeout: 120000,
        responseType: 'arraybuffer',
      });
      return response;
    } catch (error) {
      logger.error('Failed to generate report', error);
      throw  error;
    }
  };

  return {
    getTotalPaxReport,
    getSalesReport,
    getSalesReportByDepartureDate,
  };
};
